import AddIconCircleSVG from "assets/svg/add-circle-icon";
import EyeIconSVG from "assets/svg/eye-icon";
import EyeSlashIconSVG from "assets/svg/eye-slash-icon";
import _ from "lodash";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import "./styles.css";

const InputMultiline = (
  {
    rows = 4,
    autoFocus = false,
    validationPassed = false,
    showInputValidation = false,
    type = "text",
    name = "",
    label = null,
    inputContainerClass = "",
    inputIcon = null,
    iconPosition = "right", // ["left", "right"]
    placeholder = "",
    errorMessage = null,
    outsideLabel = "",
    inputClass = "",
    labelClass = "input-label",
    outsideLabelClass = "",
    paddingTop = null,
    errorMessagePosition = "top",
    showPasswordStrength = false,
    showCounter = false,
    disabledInput = false,
    masked = false,
    visible = true,
    extra = { show: false, label: "" },
    onChangeVisible = () => {},
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const padding = () => {
    let result = "1rem";
    if (showPasswordStrength) {
      result = "9rem";
    } else if (showInputValidation) {
      result = "3rem";
    } else if (showCounter) {
      result = "3.7rem";
      if (masked) {
        result = "4.5rem";
      }
    } else if (masked) {
      result = "2rem";
    }
    return result;
  };

  return (
    <div className={inputContainerClass}>
      {outsideLabel && (
        <label
          htmlFor={name}
          className={`input-label-upper ${outsideLabelClass}`}
        >
          {outsideLabel}
        </label>
      )}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="input-error-message">* {errorMessage}</p>
      )}

      <div className={`input-wrapper ${masked && "pr-3"}`}>
        {props?.value?.length > 1 && showInputValidation && (
          <div
            className={`validation-container ${
              validationPassed ? "bg-lightGreen" : "bg-red"
            } ${outsideLabel && "top-2.5"}`}
          >
            {validationPassed ? (
              <FaCheck className="icon-input" />
            ) : (
              <FaTimes className="icon-input" />
            )}
          </div>
        )}

        {inputIcon && (
          <div
            className={`h-full absolute items-center flex ${iconPosition}-3`}
          >
            {inputIcon}
          </div>
        )}

        <textarea
          id={name}
          disabled={disabledInput}
          className={`input-class ${inputClass} ${outsideLabel && "mt-2"} ${
            disabledInput && "bg-lightGray"
          }`}
          style={{
            paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem",
            paddingRight: padding(),
            borderColor: errorMessage && "red",
            paddingTop: !label ? "0.5rem" : paddingTop,
            paddingBottom: !label && "0.5rem",
          }}
          type={type}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          {...props} // so that user can select whether controlled input or uncontrolled
          ref={ref}
          rows={rows}
        />
        {label && (
          <label
            htmlFor={name}
            className={labelClass}
            style={{ paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem" }}
          >
            {label}
          </label>
        )}
        {extra.showAddButton && (
          <div className="absolute -bottom-0.5 left-28 ml-1">
            <button
              type="button"
              onClick={() => extra.onPressAdd()}
              className="btn-remove rounded-full"
            >
              <AddIconCircleSVG className="m-auto w-8 h-8" />
            </button>
          </div>
        )}
        {extra.show && (
          <span className={`extra-label ${extra.showAddButton && "ml-3"}`}>
            {extra.label}
          </span>
        )}
        {showCounter && (
          <span
            className={`text-xs opacity-70 absolute ${
              masked ? "top-5 right-10" : "top-3 right-2.5"
            }`}
          >
            {`${_.size(props?.value)}/${props?.maxLength}`}
          </span>
        )}
        {masked && (
          <button
            type="button"
            className={`masked-button-input ${label ?? "top-1.5"}`}
            onClick={onChangeVisible}
          >
            {visible ? (
              <EyeIconSVG className="mx-auto" />
            ) : (
              <EyeSlashIconSVG className="mx-auto" />
            )}
          </button>
        )}
      </div>

      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
};

const InputMultilineField = React.forwardRef(InputMultiline);

export default InputMultilineField;
