import styled from "styled-components";
import { device } from "../../screens";
import ScrollableDiv from "../commons/scrollable";
import TabSelectorClose from "../mobile-nav/tab-selector-close";
import CalendyEvent from "./calendy-event";
import EnquiryForm from "./enquiry-form/enquiry-form";
import HubspotForm from "./hubspot-form";
import LeadMagnet from "./lead-magnet/lead-magnet";
import MultipleAddress from "./multiple-address/multiple-address";
import StandardDetails from "./standard-details/standard-details";

const ContactContainer = styled.div`
  padding-left: 21px;
  padding-right: 20px;
  padding-bottom: 50px !important;
  padding-top: 28px !important;

  position: relative;

  width: 100%;
  height: 100%;

  @media ${device.xs} {
    padding-left: 46px !important;
    padding-right: 47px !important;
    padding-bottom: 50px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 50px !important;
    padding-bottom: 20px !important;
  }
`;

const btnCorner = (type) => {
  let radius = "0px";
  if (type === "rounded") {
    radius = "999px";
  } else if (type === "curved") {
    radius = "10px";
  }
  return radius;
};

const renderContact = (data) => {
  const contacts = {
    "standard-details": (data) => <StandardDetails data={data} />,
    "enquiry-form": (data) => <EnquiryForm data={data} />,
    "lead-magnet": (data) => <LeadMagnet data={data} />,
    "multiple-addresses": (data) => <MultipleAddress data={data} />,
    "calendy-event-scheduler": (data) => <CalendyEvent data={data} />,
    "hubspot-form": (data) => <HubspotForm data={data} />,
    empty: () => null,
  };

  const contactD = data.type ? contacts[data.type] : contacts.empty;

  return contactD(data);
};

const ContactPreview = ({
  campaign,
  popup,
  campaign_id,
  overlay,
  onClose,
  containerWidth = 120,
}) => {
  let data = {
    button_colour: popup?.button_colour,
    primary_colour: popup?.primary_colour,
    button_corner_style: btnCorner(popup?.button_corner_style),
    button_font_colour: popup?.button_font_colour,
    show_logo: popup?.show_logo,
    font: popup?.font,
    popup_id: popup?.id,
    campaign_id: campaign_id,
    overlay: overlay,
    width: popup?.image ? popup?.tab_image_size : containerWidth,
  };
  let payload = { ...campaign, ...data };

  return (
    <ScrollableDiv
      width={popup?.image ? popup?.tab_image_size : containerWidth}
    >
      <ContactContainer
        width={popup?.image ? popup?.tab_image_size : containerWidth}
      >
        <TabSelectorClose
          content={true}
          width={popup?.image ? Number(popup?.tab_image_size) : containerWidth}
          onClose={onClose}
        />
        {renderContact(payload)}
      </ContactContainer>
    </ScrollableDiv>
  );
};

export default ContactPreview;
