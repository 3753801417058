import PowerByIconSVG from "assets/svg/powered-by-icon";
import { Context } from "context/context";
import { useContext } from "react";

export default function HubspotForm({ popup }) {
  const { view } = useContext(Context);
  const mobile = view === "mobile";

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "contacts-details-container overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      <div className={"w-full text-center text-2xl"}>Hubspot Form</div>
      <div className="w-full text-center mt-4">Preview to see</div>
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
