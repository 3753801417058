import PowerByIconSVG from "assets/svg/powered-by-icon";
import { useEffect, useState } from "react";
import CCIconLogoContainer from "../commons/cc-logo-container";

const hubspotScriptId = "hubspot-forms-embed-script";
const hubspotDivId = "hubspot-form-target";

const HubspotForm = ({ data }) => {
  const [formLoaded, setFormLoaded] = useState(false);
  const [loadedPortalId, setLoadedPortalId] = useState();
  const [loadedFormId, setLoadedFormId] = useState();

  useEffect(() => {
    if (
      formLoaded &&
      data.portalId === loadedPortalId &&
      data.formId === loadedFormId
    ) {
      return;
    }

    setLoadedPortalId(data.portalId);
    setLoadedFormId(data.formId);

    const loadForm = () => {
      window.hbspt.forms.create({
        portalId: data.portalId,
        formId: data.formId,
        target: `#${hubspotDivId}`,
      });
      setFormLoaded(true);
    };

    if (window.hbspt) {
      loadForm();
      return;
    }

    let scriptEl = document.getElementById(hubspotScriptId);

    if (!scriptEl) {
      scriptEl = document.createElement("script");
      scriptEl.id = hubspotScriptId;
      scriptEl.type = "text/javascript";
      scriptEl.src = "//js.hsforms.net/forms/embed/v2.js";
      document.head.appendChild(scriptEl);
    }

    scriptEl.addEventListener("load", loadForm);

    return () => {
      scriptEl.removeEventListener("load", loadForm);
    };
  }, [data, loadedPortalId, loadedFormId, formLoaded]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <div id={hubspotDivId} />
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </div>
  );
};

export default HubspotForm;
