import AddressesIconSVG from "assets/svg/addresses";
import CalendyIconSVG from "assets/svg/calendy";
import ContactsIconSVG from "assets/svg/contacts";
import EnquiryIconSVG from "assets/svg/enquiry";
import LeadMagnetIconSVG from "assets/svg/leadmagnet";
import Button from "components/button/button";
import ButtonBigIcon from "components/button/button-big-with-icons";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { CONTACTOBJ } from "utils/constant";

const contact_method_types = [
  {
    value: "standard-details",
    label: "Primary Details",
    icon: ContactsIconSVG,
  },
  {
    value: "enquiry-form",
    label: "Enquiry Form",
    icon: EnquiryIconSVG,
  },
  {
    value: "lead-magnet",
    label: "Lead Magnet",
    icon: LeadMagnetIconSVG,
  },
  {
    value: "multiple-addresses",
    label: "Multiple Addresses",
    icon: AddressesIconSVG,
  },
  {
    value: "calendy-event-scheduler",
    label: "Calendly",
    icon: CalendyIconSVG,
  },
  {
    value: "hubspot-form",
    label: "HubSpot Form",
    icon: EnquiryIconSVG,
  },
];

const CampaignChooseContactMethod = ({ id }) => {
  const {
    popup,
    setPopup,
    setSelectedTab,
    setMobileTabs,
    contactsData,
    setContactsData,
  } = useContext(Context);
  const { http } = global.services;
  const [selected, setSelected] = useState(
    popup.contact_method_type || "standard-details"
  );
  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const handleSelect = (value) => {
    //save current data before update for pulling
    if (value !== selected) {
      if (!_.isEmpty(popup.contact_method_content)) {
        setContactsData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            [popup.contact_method_type]: popup.contact_method_content,
          },
        }));
      }
      setSelected(value);
      let data = update(popup, {
        contact_method_type: { $set: value },
      });
      handleOnSave(data);
    }
  };

  const handleOnSave = async (data) => {
    setState((prev) => ({
      loading: true,
      validationErrors: {
        ...prev.validationErrors,
        contact_method_type: null,
      },
    }));
    let params = {
      contact_method_type: data?.contact_method_type,
      contact_method_content: {},
    };

    if (popup.contact_method_type !== data?.contact_method_type) {
      //===============overwrite content if not the same value=================
      if (!_.isEmpty(contactsData[id]?.[data?.contact_method_type])) {
        params = {
          ...popup,
          contact_method_content: contactsData[id][data?.contact_method_type],
          contact_method_type: data?.contact_method_type,
        };
      } else {
        params = {
          ...popup,
          contact_method_content: JSON.stringify(
            _.find(CONTACTOBJ, { type: data?.contact_method_type })
          ),
          contact_method_type: data?.contact_method_type,
        };
      }
    }

    try {
      if (popup?.id) {
        setPopup({
          ...popup,
          contact_method_type: params.contact_method_type,
          contact_method_content: params.contact_method_content,
        });
        await http.put(`popups/${popup?.id}`, params);
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));

      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeStep = () => {
    if (selected === null) {
      setState((prev) => ({
        validationErrors: {
          ...prev.validationErrors,
          contact_method_type:
            "Select contact method before proceeding with the next step.",
        },
      }));
    } else {
      setSelectedTab("contactD");
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Choose Your Contact Method</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">
                How can people contact and get in touch with you?
              </p>
            </div>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">
                Select the template that works for you below and see the preview
                on the right side.
              </p>
              {state.validationErrors?.contact_method_type && (
                <p className="text-red text-xs mb-0.5">
                  * {state.validationErrors?.contact_method_type}
                </p>
              )}
              <div className="grid grid-cols-2 gap-4">
                {contact_method_types.map((obj) => (
                  <ButtonBigIcon
                    key={obj.value}
                    selected={selected && selected === obj.value}
                    buttonName={obj.label}
                    Icon={obj.icon}
                    onClick={() => handleSelect(obj.value)}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => {
                  setSelectedTab("content");
                  setMobileTabs("key-points");
                }}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={handleChangeStep}
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </div>
  );
};

export default CampaignChooseContactMethod;
