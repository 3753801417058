import Button from "components/button/button";
import Input from "components/input/input";
import InputMultilineField from "components/input/input-multiline";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useState } from "react";
import { CONTACTOBJ } from "utils/constant";

export default function HubspotFormDetails({
  handleChangeStep,
  state,
  setState,
  handleOnSave,
}) {
  const { setSelectedTab, popup } = useContext(Context);
  const [content, setContent] = useState(
    _.isEmpty(popup?.contact_method_content)
      ? _.find(CONTACTOBJ, { type: "hubspot-form" })
      : JSON.parse(popup?.contact_method_content)
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const update = {
      [name]: value,
    };
    if (name === "embed") {
      const portalId = value.match(/portalId:\s+['"](.+)['"]/)?.[1];
      if (portalId) {
        update["portalId"] = portalId;
      }
      const formId = value.match(/formId:\s+['"](.+)['"]/)?.[1];
      if (formId) {
        update["formId"] = formId;
      }
    }
    setContent((prev) => ({
      ...prev,
      ...update,
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Complete Your Contact Details</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleChangeStep(content);
          }}
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <p className="text-xl pl-1 pb-2">Embed your HubSpot form</p>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">
                Enter your HubSpot embed code here:
              </p>
              {/* title */}
              <div>
                <InputMultilineField
                  inputContainerClass="input-container"
                  outsideLabelClass="sr-only"
                  paddingTop="1.5rem"
                  value={content.embed}
                  maxLength={1000}
                  outsideLabel="Embed Code"
                  autoFocus={true}
                  name="embed"
                  onChange={handleInputChange}
                  onBlur={() => handleOnSave(content)}
                  errorMessage={state.validationErrors?.embed}
                />
              </div>
              <Input
                inputContainerClass="input-container"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                value={content.portalId}
                maxLength={255}
                label="Portal ID"
                name="portalId"
                type="text"
                disabled
              />
              <Input
                inputContainerClass="input-container"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                value={content.formId}
                maxLength={255}
                label="Form ID"
                autoFocus={true}
                name="formId"
                type="text"
                disabled
              />
            </div>
            {/* <p className="text-sm">
              Need more help?{" "}
              <a
                className={"text-blue font-bold underline"}
                target={"_blank"}
                href="https://support.conversioncow.com/hc/en-us/articles/6544925578255-How-to-Set-Calendly-Event-Contact-Method"
                rel="noreferrer"
              >
                Find out more
              </a>
            </p> */}
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("contactM")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
