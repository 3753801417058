const interactionNames = {
  "address-click": "Address Clicked",
  "button-click": "Button Link Clicked",
  "email-click": "Email Address Clicked",
  "telephone-click": "Phone Number Clicked",
  "email-copy": "Email Address Copied",
  "telephone-copy": "Phone Number Copied",
  "form-input": "Form Data Inputted",
  "form-submitted": "Form Submitted",
  "video-start": "Video Played",
  "calendly-event-scheduled": "Calendly Booking Scheduled",
  "calendly-event-datetime-selected": "Calendly Time Selected",
  "hubspot-form-submitted": "Hubspot Form Submitted",
  "map-click": "Map Clicked",
};

export default function TopEvents({ dataSource }) {
  return (
    <table className="w-full divide-y divide-gray">
      <tbody>
        {dataSource ? (
          Object.entries(dataSource)
            .sort((a, b) => b[1] - a[1])
            .map(([key, value]) => (
              <tr key={key}>
                <td className="p-1">{interactionNames[key] || key}</td>
                <td className="p-1 text-right font-bold">{value}</td>
              </tr>
            ))
        ) : (
          <tr>
            <td className="p-1">No event data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
